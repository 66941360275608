import React from 'react';
import {
  Autocomplete, Box, Grid, InputAdornment, TextField,
  Typography,
} from '@mui/material';
import debounce from 'lodash.debounce';
import { ValidatorElement } from './validator';
import { AudioButton } from '../../../components/AudioButton';

const { REACT_APP_API_URL } = process.env;

export const SongSelectField = ({
  fullWidth = true, value, required, onChange, label, noOptionsText, loadingText, ...props
}) => {
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [resetAudio, setResetAudio] = React.useState(false);
  const [selectedSongUrl, setSelectedSongUrl] = React.useState(null);

  const throttledFetch = React.useMemo(
    () => debounce(async (request, callback) => {
      if (!request) {
        callback([]);
        return;
      }

      setIsLoading(true);

      const response = await fetch(`${REACT_APP_API_URL}/songs/search`, { // fixedEncodeURIComponent(request)
        method: 'POST',
        mode: 'cors',
        redirect: 'manual',
        headers: {
          'User-Agent': 'PostmanRuntime/7.28.4',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          search: request,
        }),
      });

      if (!response.ok) {
        throw new Error(await response.text());
      }

      try {
        const { results } = await response.json();

        callback(results);
        setIsLoading(false);
      } catch (err) {
        callback([]);
        setIsLoading(false);
      }
    }, 400),
    [],
  );

  React.useEffect(() => {
    let isMounted = true;

    throttledFetch(inputValue, (results) => {
      if (isMounted) {
        setOptions(results);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [inputValue, throttledFetch]);

  return (
    <Box sx={{ width: '100%', '& >div': { width: '100%' } }}>
      <Autocomplete
        noOptionsText={noOptionsText}
        loadingText={loadingText}
        getOptionLabel={(option) => `${option.artistName} - ${option.trackName}`}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        disableClearable
        loading={isLoading}
        fullWidth={fullWidth}
        includeInputInList={false}
        filterSelectedOptions
        onChange={(event, newValue) => {
          setSelectedSongUrl(newValue ? newValue.previewUrl : null);
          onChange({ target: { value: newValue } });
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <>
            <TextField
              {...params}
              label={label}
              fullWidth
              {...props}
              InputProps={{
                ...params.InputProps,
                ...props.InputProps,
                startAdornment: selectedSongUrl && (
                  <InputAdornment sx={{ mr: 0.8 }}>
                    <AudioButton
                      size="24px"
                      iconSize="20px"
                      url={selectedSongUrl}
                      reset={resetAudio}
                      onPlay={() => { setResetAudio(true); setTimeout(() => { setResetAudio(false); }); }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </>
        )}
        renderOption={(optionProps, option) => (
          <li {...optionProps} key={option.trackId}>
            <Grid container alignItems="center">
              <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                <Box component="img" alt="Preview" src={option.artworkUrl100} sx={{ borderRadius: 2, width: '64px', height: '64px', objectFit: 'cover' }} />
              </Grid>
              <Grid item xs sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', pl: 2 }}>
                <Typography variant="body2" color="text.secondary" sx={{ margin: 0, padding: 0 }}>
                  <b>{option.artistName}</b>
                </Typography>
                <Typography variant="body2" color="text.secondary" sx={{ margin: 0, padding: 0 }}>
                  {option.trackName}
                </Typography>
              </Grid>

            </Grid>
          </li>
        )}
      />

      <ValidatorElement
        {...value && { value }}
        validators={[
          ...required ? ['required'] : [],
        ]}
      />
    </Box>
  );
};

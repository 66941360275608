import React from 'react';
import { Box, Button as ButtonBase } from '@mui/material';
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

export const Button = ({ color, label, variant = 'contained', alignment = 'center', marginTop, href, highlights, fontSize, font, paddingX, ...props }) => {
  const currentTheme = useTheme();
  const navigate = useNavigate();

  const newTheme = createTheme({
    ...currentTheme,
    ...color && { palette: {
      ...currentTheme.palette,
      primary: {
        main: color,
      },
    } },
  });

  return (
    <ThemeProvider theme={newTheme}>
      <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: alignment, pt: marginTop }}>
        <ButtonBase
          {...props}
          variant={variant}
          color="primary"
          sx={{
            ...props.sx,
            fontSize,
            fontFamily: font,
            px: paddingX,
          }}
          onClick={() => {
            if (highlights) {
              navigate('#highlights');
              return;
            }

            if (href.startsWith('http')) {
              window.location.href = href;
            } else {
              navigate(href);
            }
          }}
        >
          {label}
        </ButtonBase>
      </Box>
    </ThemeProvider>
  );
};

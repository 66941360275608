import {
  Box, CircularProgress, Container, styled, useTheme,
} from '@mui/material';
import React, {
  useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import LightGallery from 'lightgallery/react';
import { SiteContext } from '../../contexts';
import lgVideo from '../../vendor/lightgallery/lgVideo';
import { Video } from './video';
import { ThemeProvider } from './themeProvider';

const MAX_MESSAGES_COUNT = 20;
const OPACITY = 0.8;
const SPACING = 50;

const StyledImage = styled('img')({});

const loadMessages = async (site, formId, sinceDate) => {
  const response = await fetch(`/api/site/${site.id}/messages?since=${sinceDate.toISOString()}&formId=${formId}`, {
    method: 'POST',
  });

  if (!response.ok) {
    throw new Error(await response.text());
  }

  return response.json();
};

const getNewMessage = async (site, formId, sinceDate, priorityList, usedIds) => {
  const response = await fetch(`/api/site/${site.id}/messages?since=${sinceDate.toISOString()}&formId=${formId}&onlyNewMessage=true`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      priorityList,
      usedIds,
    }),
  });

  if (!response.ok) {
    throw new Error(await response.text());
  }

  return response.json();
};

const getRandomColor = () => Math.floor(Math.random() * 16777215).toString(16);

function MessageAttachment({ attachment }) {
  const Content = (() => {
    if (attachment.type === 'video') {
      return (
        <Box sx={{ width: '100%', height: '100%', position: 'relative' }}>
          <Video
            controls={false}
            url={`/files/${attachment.path}`}
            sx={{
              width: '100%',
              height: '100%',
              '& video': {
                objectFit: 'cover',
              },
              '& .video-play-container': {
                marginTop: '-2px',
              },
              '& .video-play': {
                width: '60px',
                height: '60px',
                opacity: 0.6,
              },
            }}
          />
          <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box
              component="a"
              className="lightbox-image"
              data-lg-size="1280-720"
              data-video={JSON.stringify({
                source: [{ src: `/files/${attachment.path}`, type: 'video/mp4' }],
                tracks: [{ src: '/videos/title.txt', kind: 'captions', srclang: 'en', label: 'English', default: 'true' }],
                attributes: { preload: true, controls: true },
              })}
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
              }}
            />
          </Box>
        </Box>
      );
    }

    return (
      <StyledImage
        class="lightbox-image"
        className="lightbox-image"
        data-src={`/api/uploads/w_2000,c_limit/${attachment.id}`}
        src={`/api/uploads/w_2000,c_limit/${attachment.id}`}
        sx={{ width: '100%', height: '100%', objectFit: 'cover' }}
      />
    );
  })();

  return (
    <>
      <LightGallery
        speed={500}
        plugins={[lgVideo]}
        selector=".lightbox-image"
        vimeoPlayerParams={{ muted: 0 }}
      >
        {Content}
      </LightGallery>
    </>
  );
}

function Message({ name, message, isOdd, color, attachment, isInitial, animationDuration = 600, showRandomColorVerticalBars }) {
  const theme = useTheme();

  const [isInitialized, setIsInitialized] = useState(false);
  const [isAnimationPrepared, setIsAnimationPrepared] = useState(false);
  const elementRef = useRef(null);

  const bubbleColor = theme.palette.primary.main;
  const textColor = theme.palette.primary.contrastText;

  const text = message?.replace(/\n\n+/g, '\n')?.trim();

  const animationStyles = useMemo(() => {
    if (isInitial) {
      return {};
    }

    if (!isInitialized) {
      return {
        position: 'absolute',
        top: 0,
        left: 0,
        opacity: 0,
        pointerEvents: false,
      };
    }

    if (!isAnimationPrepared) {
      return {
        opacity: 0,
        marginTop: `${-(elementRef.current.scrollHeight)}px`,
      };
    }

    return {
      opacity: 1,
      marginTop: 0,
      transition: `all ${animationDuration}ms ease-in-out`,
    };
  }, [isInitial, isInitialized, isAnimationPrepared, animationDuration]);

  useEffect(() => {
    setTimeout(() => setIsInitialized(true), 2000);
  }, []);

  useEffect(() => {
    if (!isInitialized) {
      return;
    }

    setTimeout(() => setIsAnimationPrepared(true));
  }, [isInitialized]);

  return (
    <Box
      ref={elementRef}
      sx={{
        borderRadius: 2,
        border: `thin solid ${bubbleColor}`,
        ...showRandomColorVerticalBars && (
          !isOdd ? {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          } : {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
          }
        ),
        backgroundColor: bubbleColor,
        fontSize: { xs: '15px' },
        padding: '25px 25px',
        lineHeight: { xs: 1.6 },
        position: 'relative',
        '&::after': {
          content: '""',
          marginTop: '-30px',
          paddingTop: '0px',
          position: 'absolute',
          bottom: '-25px',
          left: '6%',
          borderWidth: '30px 0 0 30px',
          borderStyle: 'solid',
          borderColor: `${bubbleColor} transparent`,
          display: 'block',
          width: 0,
          ...!isOdd && {
            borderWidth: '30px 30px 0 0',
            left: 'auto',
            right: '6%',
          },
        },
        ...animationStyles,
        marginBottom: `${SPACING}px`,
      }}
    >
      <Box sx={{ color: textColor }}>
        {text?.length > 0 && (
          <Box sx={{
            opacity: OPACITY,
            whiteSpace: 'pre-line',
            position: 'relative',
            '&::before': {
              content: '"“"',
              fontFamily: 'Georgia',
              fontSize: '34px',
              lineHeight: 0,
              display: 'inline-block',
              position: 'absolute',
              top: '3px',
              left: '0px',
              color: textColor,
            },
            '&::after': {
              content: '"”"',
              fontFamily: 'Georgia',
              fontSize: '34px',
              lineHeight: 0,
              display: 'inline-block',
              position: 'absolute',
              bottom: '-15px',
              right: '0px',
              color: textColor,
            },
          }}
          >
            {text}
          </Box>
        )}

        <Box sx={{
          display: 'flex',
          mt: text?.length > 0 ? '15px' : 0,
          alignItems: 'flex-end',
          justifyContent: { xs: 'left', sm: 'center' },
          position: 'relative',
        }}
        >
          {attachment && (
            <Box sx={{
              height: { xs: '70px', sm: '110px' },
              width: { xs: '140px', sm: '220px' },
              borderRadius: 1,
              cursor: 'pointer',
              overflow: 'hidden',
              // marginTop: '-10px',
              ...attachment.vertical && {
                height: { xs: '90px', sm: '150px' },
                width: { xs: '90px', sm: '150px' },
                '& .lg-react-element': {
                  height: '100%',
                },
              },
            }}
            >
              <MessageAttachment
                attachment={attachment}
              />
            </Box>
          )}
          <Box sx={{
            position: { xs: 'initial', sm: 'absolute' },
            right: '0px',
            display: 'inline',
            fontStyle: 'italic',
            opacity: OPACITY,
            bottom: '0px',
            marginLeft: 'auto',
            textAlign: 'right',
            ...!attachment ? {
              position: 'initial',
            } : {
              maxWidth: '200px',
            },
          }}
          >
            {name}
          </Box>
        </Box>
      </Box>
      {showRandomColorVerticalBars && (
        <Box sx={{
          position: 'absolute',
          left: '-1px',
          top: '-1px',
          height: 'calc(100% + 2px)',
          borderLeft: `6px solid #${color}`,
          ...!isOdd && {
            left: 'auto',
            right: '-1px',
          },
        }}
        />
      )}
    </Box>
  );
}

export const ShowMessages = ({
  label, maxWidth = 'md', vMixFormId, frequency = 7, height = 1000,
  bubbleColor, backgroundColor = 'white', outerBackgroundColor = 'white',
  animationDuration = 1000, showRandomColorVerticalBars = true,
}) => {
  const [messages, setMessages] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const { site } = useContext(SiteContext);

  const sinceDate = useRef(new Date(0));
  const priorityListRef = useRef([]);
  const usedIdsRef = useRef([]);
  const isOddMessage = useRef(false);

  useEffect(() => {
    const buildMessage = (message, isInitial) => {
      isOddMessage.current = !isOddMessage.current;

      return {
        ...message,
        id: `${message.id }_${ new Date().valueOf()}`,
        isOdd: isOddMessage.current,
        isInitial,
        color: getRandomColor(),
      };
    };

    (async () => {
      const {
        messages: oldMessages, priorityList: initialPriorityList, usedIds: initialUsedIds,
      } = await loadMessages(site, vMixFormId, sinceDate.current);

      priorityListRef.current = initialPriorityList;
      usedIdsRef.current = initialUsedIds;
      sinceDate.current = new Date();
      setMessages(oldMessages.map((x) => buildMessage(x, true)));
      setIsLoaded(true);

      setInterval(async () => {
        const { message: newMessage, priorityList, usedIds } = await getNewMessage(
          site, vMixFormId, sinceDate.current, priorityListRef.current, usedIdsRef.current,
        );

        priorityListRef.current = priorityList;
        usedIdsRef.current = usedIds;
        sinceDate.current = new Date();

        if (!newMessage) {
          return;
        }

        setMessages((items) => [buildMessage(newMessage), ...items].slice(0, MAX_MESSAGES_COUNT));
      }, frequency * 1000);
    })();
  }, []);

  return (
    <ThemeProvider primaryColor={bubbleColor || '#26272b'}>
      <Box sx={{ backgroundColor: outerBackgroundColor }}>
        <Container
          maxWidth={maxWidth}
          sx={{ backgroundColor }}
        >
          <h1>{label}</h1>
          <Box
            sx={{
              height: !isLoaded || messages.length > 0 ? height : 0,
              overflow: 'hidden',
              paddingTop: `${SPACING / 2}px`,
            }}
          >
            {!isLoaded && (
              <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </Box>
            )}
            {messages.map((message) => (
              <Box key={message.id}>
                <Message
                  {...message}
                  animationDuration={animationDuration}
                  showRandomColorVerticalBars={showRandomColorVerticalBars}
                />
              </Box>
            ))}

          </Box>

        </Container>
      </Box>
    </ThemeProvider>
  );
};

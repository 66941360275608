/* eslint-disable no-continue */
import {
  Box, Container, Grid, styled, useMediaQuery, useTheme,
} from '@mui/material';
import React, {
  useCallback,
  useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import { Transition } from 'react-transition-group';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { SiteContext } from '../../contexts';
import { LightGalleryContext } from '../../contexts/lightgallery';

const StyledImage = styled('img')({ width: '100%', height: '100%', objectFit: 'cover' });

function shuffle(array) {
  const copy = [...array];
  copy.sort(() => Math.random() - 0.5);

  return copy;
}

const duration = 300;

const transitionStyles = {
  entering: { opacity: 0, transform: 'scale(0.9)' },
  entered: { opacity: 1, transform: 'scale(1.0)' },
  exiting: { opacity: 1, transform: 'scale(1.0)' },
  exited: { opacity: 0, transform: 'scale(0.9)' },
};

function ImageCell({ galleryGroupId, item, absolute, visible, sx }) {
  const { openGallery } = useContext(LightGalleryContext);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);

  const Wrapper = absolute ? (
    ({ children }) => (
      <Box sx={{ position: 'absolute', left: 0, top: 0, width: '100%', height: '100%' }}>{children}</Box>
    )
  ) : React.Fragment;

  return (
    <Transition in={isVisible} timeout={duration}>
      {(state) => (
        <Box
          component="div"
          onClick={() => {
            openGallery(galleryGroupId, item.index);
          }}
          className="lightbox-image"
          data-src={item.video ? `https://vimeo.com/${item.id}` : `/api/uploads/w_2000,c_limit/${item.id}`}
          data-thumb={item.video ? item.thumbnail : `/api/uploads/w_200,c_limit/${item.id}`}
          sx={[sx, {
            transition: `all ${duration}ms ease-in-out`,
            opacity: 0,
            cursor: 'pointer',
            overflow: 'hidden',
            ...transitionStyles[state],
            '& .cell-content': {
              transition: 'all 400ms',
            },
            '&:hover .cell-content': {
              transform: 'scale(1.02)',
            },
          }]}
        >
          <Wrapper>
            <StyledImage
              className="cell-content"
              src={item.video ? item.thumbnail : `/api/uploads/w_2000,c_limit/${item.id}`}
            />
            {item.video && (
              <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.0)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <PlayCircleOutlineIcon htmlColor="white" sx={{ fontSize: 60, opacity: 0.8 }} />
              </Box>
            )}
          </Wrapper>
        </Box>
      )}
    </Transition>
  );
}

export const PhotoMosaic = ({ images, videos, galleryGroupName, galleryGroupOrder, galleryOnly, numberOfColumns = 5, frequency = 8000, maxWidth = false, ...rest }) => {
  const theme = useTheme();
  const galleryGroupId = useRef(`${Math.floor(Math.random() * 10000)}_${new Date().valueOf()}`).current;
  const { registerGroup } = useContext(LightGalleryContext);
  const [files, setFiles] = useState([]);
  const [isHiding, setIsHiding] = useState(false);
  const { site } = useContext(SiteContext);
  const columns = useMemo(() => [...new Array(numberOfColumns).keys()], [numberOfColumns]);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [selectedSlide, setSelectedSlide] = useState(0);
  const [randomSeed, setRandomSeed] = useState(new Date().valueOf());
  const spacing = 1;

  const actualNumberOfColumns = useMemo(() => {
    if (isSmallScreen) {
      return 3;
    }

    return numberOfColumns;
  }, [isSmallScreen]);

  const buildSlide = useCallback((array) => {
    const slide = [];
    let isUnfinished = false;
    for (let colIdx = 0; colIdx < actualNumberOfColumns; colIdx += 1) {
      for (let rowIdx = 0; rowIdx < 2; rowIdx += 1) {
        const item = array.pop();
        if (!item) {
          isUnfinished = true;
          break;
        }

        if (rowIdx === 0 && item.isVertical && Math.random() > 0.1) {
          slide.push({ ...item, id: item.id, vertical: true });
          break;
        }

        slide.push({ ...item, id: item.id });
      }
    }

    return { slide, isUnfinished };
  }, [actualNumberOfColumns]);

  const slides = useMemo(() => {
    const result = [];
    const shuffled = shuffle(files);
    for (;;) {
      const { slide, isUnfinished } = buildSlide(shuffled);
      if (!slide || !slide.length || isUnfinished) {
        break;
      }

      result.push(slide);
    }

    return result.map((slide) => {
      const groups = [];
      let currentGroup = [];
      for (let i = 0; i < slide.length; i += 1) {
        const item = slide[i];

        if (currentGroup.length === 2) {
          groups.push({ vertical: false, items: currentGroup });
          currentGroup = [];
        }

        if (item.vertical) {
          groups.push({ vertical: true, item });
        } else {
          currentGroup.push(item);
        }
      }

      if (currentGroup.length) {
        groups.push({ vertical: false, items: currentGroup });
      }

      return groups;
    });
  }, [files, columns, buildSlide, randomSeed]);

  const fetchFilesMetadata = async (ids) => {
    const response = await fetch(`/api/site/${site.id}/files`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ids }),
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }
    const { files: items } = await response.json();

    return items;
  };

  const fetchVideoMetadata = async (ids) => {
    const response = await fetch(`/api/site/${site.id}/vimeo-videos`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ids }),
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }
    const { files: items } = await response.json();

    return items;
  };

  useEffect(() => {
    (async () => {
      const ids = images.map((x) => x.image);
      const videoIds = videos.filter((x) => x.video).map((x) => {
        const parts = x.video.split('/');
        return parts[parts.length - 1];
      });

      const [filesMetadata, videoMetadata] = await Promise.all([
        fetchFilesMetadata(ids),
        fetchVideoMetadata(videoIds),
      ]);

      setFiles([
        ...filesMetadata.map((file) => ({
          ...images.find((x) => x.image === file.id) || {},
          ...file,
          isVertical: file.height > file.width,

        })),
        ...videoMetadata.map((file) => ({
          ...videos.find((x) => {
            const parts = x.video.split('/');
            const id = parts[parts.length - 1];

            return id === file.id;
          }) || {},
          ...file,
          video: true,
          isVertical: false,
        })),
      ].map((x, idx) => ({
        ...x,
        index: idx,
      })));
    })();
  }, []);

  useEffect(() => {
    let value = 0;
    setSelectedSlide(value);

    const interval = setInterval(async () => {
      setIsHiding(true);

      await new Promise((resolve) => setTimeout(resolve, duration * 2));

      if (value < slides.length - 1) {
        value += 1;
        setSelectedSlide(value);
      } else {
        setRandomSeed(new Date().valueOf());
      }

      setIsHiding(false);
    }, frequency + duration);

    return () => {
      clearInterval(interval);
    };
  }, [slides]);

  useEffect(() => {
    if (!files.length) {
      return;
    }

    registerGroup(galleryGroupId, galleryGroupName || 'Photo mosaic', galleryGroupOrder || 0, files.map((item, itemIdx) => ({
      ...item,
      id: item.index,
      src: item.video ? `https://vimeo.com/${item.id}` : `/api/uploads/w_2000,c_limit/${item.id}`,
      thumb: item.video ? item.thumbnail : `/api/uploads/w_200,c_limit/${item.id}`,
    })));
  }, [files]);

  if (!slides[selectedSlide] || galleryOnly) {
    return null;
  }

  return (

    <Container maxWidth={maxWidth} disableGutters>
      <Box display="flex">
        <Grid container spacing={spacing} columns={{ xs: 3, md: numberOfColumns }}>
          {slides[selectedSlide].map((group) => (
            group.vertical ? (
              <Grid item xs={1} key={group.item.id} sx={{ mb: spacing }}>
                <ImageCell
                  galleryGroupId={galleryGroupId}
                  item={group.item}
                  sx={{ width: '100%', pb: `calc(200% + ${8}px)`, position: 'relative', mb: spacing }}
                  absolute
                  visible={!isHiding}
                />
              </Grid>
            ) : (
              <Grid item xs={1} key={group.items[0].id}>
                {group.items.map((item) => (
                  <ImageCell
                    galleryGroupId={galleryGroupId}
                    item={item}
                    key={item.id}
                    sx={{ width: '100%', pb: '100%', position: 'relative', mb: spacing }}
                    absolute
                    visible={!isHiding}
                  />
                ))}
              </Grid>
            )
          ))}
        </Grid>
      </Box>
    </Container>

  );
};

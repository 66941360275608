import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { SiteContext } from '../../contexts';

export const LanguageSelectionModal = ({
  title,
  message,
  confirmButtonText,
}) => {
  const { site } = useContext(SiteContext);
  const [languageId, setLanguageId] = useState(site.language.id);
  const [isVisible, setIsVisible] = useState(false);

  const onSubmit = () => {
    const lang = site.languages.find((x) => x.id === languageId);
    const { language } = site;

    if (language.id !== lang.id) {
      if (!language.implicit && lang.default) {
        window.location.href = `${window.location.origin}${window.location.pathname.replace(`/${language.code.toLowerCase()}`, '')}${window.location.search}`;
      } else if (!language.implicit && !lang.default) {
        window.location.href = `${window.location.origin}/${lang.code.toLowerCase()}${window.location.pathname.replace(`/${language.code.toLowerCase()}`, '')}${window.location.search}`;
      } else {
        window.location.href = `${window.location.origin}/${lang.code.toLowerCase()}${window.location.pathname}${window.location.search}`;
      }
    }

    setIsVisible(false);

    localStorage.setItem(
      `${site.id}-language-selection-modal`, 'true',
    );
  };

  useEffect(() => {
    if (!site.id) {
      return;
    }

    const isClosed = localStorage.getItem(
      `${site.id}-language-selection-modal`,
    );

    if (isClosed) {
      return;
    }

    setIsVisible(true);
  }, [site.id]);

  return (
    <Dialog open={isVisible} maxWidth={700} disableScrollLock>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 1, minWidth: 350 }}>{message}</Box>
        <FormControl>
          <RadioGroup
            value={languageId}
            onChange={(e) => {
              setLanguageId(e.target.value);
            }}
          >
            {site.languages.map((lang) => (
              <FormControlLabel key={lang.id} value={lang.id} control={<Radio />} label={`[${lang.code}] ${lang.name}`} />
            ))}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onSubmit}
          variant="contained"
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

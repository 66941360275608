import { Box, styled } from '@mui/material';
import React, { useContext, useMemo } from 'react';
import { SiteContext } from '../../contexts';
import Drawer from './drawer';
import LanguageSelector from './languageSelector';
import { Link } from './link';
import { ThemeProvider } from './themeProvider';

const SpanBase = styled('span')({});
const Span = ({ children, ...props }) => <SpanBase {...props} dangerouslySetInnerHTML={{ __html: children }} />;
const Image = styled('img')({});

export const Navbar = ({ id, color, font, fontColor, menuItems, logo }) => {
  const { site: { pages, languages } } = useContext(SiteContext);

  const fullScreenImage = useMemo(() => {
    const modules = pages.map((x) => x.modules).flat();

    return modules.find((x) => x.systemType === 'full-screen-image');
  }, [pages]);

  const filteredMenuItems = useMemo(() => {
    const modules = JSON.stringify(pages);

    return menuItems.filter((item) => {
      if (!item.href || !item.href.startsWith('#')) {
        return true;
      }

      return modules.includes(`"id":"${item.href.replace('#', '')}"`);
    });
  }, [pages]);

  return (
    <ThemeProvider primaryColor={color}>
      <Box sx={{
        ...!fullScreenImage && {
          marginTop: {
            md: '64px',
            xs: '50px',
          },
        },
      }}
      >
        <Box
          id={id}
          sx={{
            top: 0,
            color: 'var(--primary-text-color)',
            width: '100%',
            height: {
              md: 64,
              xs: 50,
            },
            zIndex: 1000,
            display: 'flex',
            alignItems: 'center',
            fontFamily: font,
            justifyContent: 'center',
            backgroundColor: 'var(--primary-color)',
            ...!fullScreenImage && {
              position: 'fixed',
            },
          }}
        >
          <Drawer width="250px" sx={{ left: 10, position: 'absolute' }}>
            <Box sx={{ pl: 1,
              pt: 8,
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              backgroundColor: 'black' }}
            >
              {filteredMenuItems.map((menuItem) => (
                <Link
                  key={menuItem}
                  to={menuItem.href}
                  sx={{ m: {
                    xs: 1,
                  },
                  color: 'white',
                  fontSize: '18px',
                  textTransform: 'uppercase' }}
                >
                  {menuItem.text}
                </Link>
              ))}
            </Box>
          </Drawer>
          {filteredMenuItems.map((menuItem) => (
            <Link
              key={menuItem}
              to={menuItem.href}
              sx={{
                mx: {
                  sm: 3,
                  xs: 1,
                },
                color: 'inherit',
                display: {
                  md: 'block',
                  xs: 'none',
                },
                fontSize: '15px',
                textTransform: 'uppercase',
                '&:hover, &:hover span': {
                  color: 'gray',
                  textDecoration: 'none',
                },
              }}
            >
              <Span sx={{ color: fontColor }}>
                {menuItem.text}
              </Span>
            </Link>
          ))}
          <Box sx={{
            height: '100%',
            display: {
              md: 'none',
              xs: 'flex',
            },
            flexGrow: 1,
            justifyContent: 'center',
          }}
          >
            <Image src={logo} sx={{ height: '100%', paddingY: 1 }} />
          </Box>
          {languages.length > 1 && (
            <LanguageSelector
              color="inherit"
              sx={{
                color: fontColor,
                right: {
                  sm: 10,
                  xs: 0,
                },
                position: 'absolute',
                fontWeight: 'bold',
              }}
            />
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

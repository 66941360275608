import { Box, Container } from '@mui/material';
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { useCountUp } from 'react-countup';
import { useInView } from 'react-intersection-observer';
import { SiteContext } from '../../contexts';

const FADE_IN_DURATION = 1000;
const COUNT_DURATION = 0.6;

const StatValue = ({ stat, data }) => {
  const ref = useRef();
  const { ref: containerRef, inView } = useInView({
    fallbackInView: true,
  });
  const { update } = useCountUp({
    ref,
    end: 0,
    delay: FADE_IN_DURATION,
    duration: COUNT_DURATION,
  });

  useEffect(() => {
    if (!inView) {
      update(0);
      return;
    }

    update(data);
  }, [data, inView]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        mb: 4,
        opacity: !inView ? 0 : 1,
        transition: `opacity ${FADE_IN_DURATION}ms`,
      }}
      ref={containerRef}
    >
      <Box component="span" ref={ref} sx={{ fontSize: '72px', lineHeight: 1, color: stat.color, mb: 2 }} />
      <Box component="span" sx={{ fontSize: '22px' }}>
        {stat.label}
      </Box>
    </Box>
  );
};

export const StatisticsCounter = ({ maxWidth = 'lg', stats }) => {
  const [statsData, setStatsData] = useState(null);
  const { site } = useContext(SiteContext);

  const loadStats = async () => {
    const response = await fetch(`/api/site/${site.id}/stats?types=${stats.map((x) => x.type).join(',')}`, {
      method: 'get',
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const { stats: items } = await response.json();

    return items;
  };

  useEffect(() => {
    (async () => {
      const items = await loadStats();
      setStatsData(items);
    })();
  }, []);

  return (
    <Container maxWidth={maxWidth}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {stats.map((stat) => (
          <Box key={stat.type} sx={{ width: { xs: '100%', md: `${100 / 3}%` }, display: 'flex', justifyContent: 'center' }}>
            <StatValue stat={stat} data={statsData && statsData[stat.type]} />
          </Box>
        ))}
      </Box>
    </Container>
  );
};

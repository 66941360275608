import { Box, Container } from '@mui/material';
import React from 'react';
import ReactPlayer from 'react-player/lazy';
import InnerHTML from 'dangerously-set-html-content';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

const HOVER_DURATION = 1;

export const Video = ({
  controls = true,
  ratio = '56.25%',
  aspectRatio,
  sx,
  header,
  text,
  disableGutters = true,
  hoverText,
  url: urlProp,
  localizedVideo,
  ...props
}) => {
  const url = localizedVideo || urlProp;
  if (!url) {
    return null;
  }

  const textLength = text && new DOMParser().parseFromString(text, 'text/html').querySelector('html').textContent.trim().length;

  const withTextSection = !!header?.length || !!textLength;

  return (
    <Container maxWidth={withTextSection ? 'lg' : 'md'} disableGutters={disableGutters}>
      <Box sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
      }}
      >
        <Box sx={{
          width: { xs: '100%', md: withTextSection ? `${0.7 * 100}%` : '100%' },
          position: 'relative',
        }}
        >
          <Box sx={{
            position: 'relative',
            paddingBottom: aspectRatio ? `${aspectRatio}%` : ratio, // Player ratio: 100 / (1280 / 720)
            ...sx,
          }}
          >
            <ReactPlayer
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              width="100%"
              height="100%"
              controls={controls}
              url={url}
              playsinline={props.playsinline ?? true}
              {...props}
            />
            {!controls && (
              <Box
                className="video-play-container"
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: 'rgba(0,0,0,0.0)',
                  transition: `background .${HOVER_DURATION}s`,
                  ...hoverText && {
                    '&:hover': {
                      background: 'rgba(0,0,0,0.8)',
                      '& .video-play': {
                        opacity: 0.0,
                      },
                      '& .hover-text': {
                        opacity: 1.0,
                      },
                    },
                  },
                }}
              >
                <PlayCircleOutlineIcon
                  htmlColor="white"
                  className="video-play"
                  sx={{
                    fontSize: 60,
                    opacity: 0.8,
                    transition: `opacity .${HOVER_DURATION}s`,
                  }}
                />
                <Box
                  className="hover-text"
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    opacity: 0.0,
                    color: 'white',
                    fontSize: '22px',
                  }}
                >
                  {hoverText}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        {withTextSection && (
          <Box sx={{
            width: { xs: '100%', md: withTextSection ? `${0.3 * 100}%` : '100%' },
            pl: { xs: 0, md: 4 },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
          >
            {header?.length && (
              <Box sx={{
                mt: { xs: 2, md: 5 },
                px: 1,
                color: 'var(--primary-text-color)',
                fontSize: {
                  lg: '1.4rem',
                  md: '1.2rem',
                  xs: '1.4rem',
                },
                fontWeight: 'bold',
                backgroundColor: 'var(--primary-color)',
                alignSelf: { xs: 'center', md: 'initial' },
              }}
              >
                <span>{header}</span>
              </Box>
            )}
            {text?.length && (
              <Box sx={{ mt: { xs: 1, md: 3 } }}>
                <InnerHTML html={text} />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Container>
  );
};

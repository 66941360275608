import { Box } from '@mui/material';
import React from 'react';

export const FormBlockOfText = ({ text, fontFamily, fontWeight, fontSize, marginTop, fontColor }) => {
  const isHtml = text.includes('<html>');

  return (
    <Box
      component="div"
      sx={{
        ...isHtml ? {
          marginTop: '-1em',
          marginBottom: '-1em',
        } : {
          whiteSpace: 'pre-line',
          '& p': {
            margin: 0,
          },
        },
        color: fontColor || 'var(--text-primary)',
        mt: marginTop,
        ...fontFamily && {
          fontFamily,
        },
        ...fontWeight && {
          fontWeight,
        },
        ...fontSize && {
          fontSize: `${fontSize}rem`,
        },
      }}
    >
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </Box>
  );
};

import React, { useContext } from 'react';
import { PageContext, SiteContext } from '../contexts';
import { PageRenderer } from '../services';

export default function ContentPage({ page }) {
  const { site: { fonts } } = useContext(SiteContext);
  const pageRenderer = new PageRenderer({ ...page, fonts });

  return (
    <PageContext.Provider value={page}>
      {pageRenderer.render()}
    </PageContext.Provider>
  );
}

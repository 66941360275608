import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Fab from '@mui/material/Fab';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, MenuItem,
  Select,
} from '@mui/material';
import {
  ContentPage, EditorPage, ErrorPage, NotFoundPage, PageLoading,
} from './components';
import { SiteContext } from './contexts';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-video.css';
import { LightGalleryProvider } from './contexts/lightgallery';

const now = new Date();

export default function App() {
  const { site, isSiteLoading, siteLoadingError, isDeveloperMode } = useContext(SiteContext);
  const [isSettingsModalOpened, setIsSettingsModalOpened] = useState(false);
  const [currentKeyDate, setCurrentKeyDate] = useState(null);

  const keyDates = useMemo(() => {
    if (!site || !isDeveloperMode) {
      return null;
    }

    const items = [now.toISOString(), ...new Set(site.keyDates)].map((x) => new Date(x));
    items.sort((a, b) => a.valueOf() - b.valueOf());

    return items;
  }, [isDeveloperMode, site]);

  useEffect(() => {
    if (!keyDates) {
      return;
    }

    const searchParams = new URLSearchParams(window.location.search);
    const currentDate = searchParams.get('date');
    if (currentDate) {
      setCurrentKeyDate(currentDate);
      return;
    }

    setCurrentKeyDate([...keyDates].reverse().find((x) => new Date() > x)?.toISOString());
  }, [keyDates]);

  if (isSiteLoading) {
    return <PageLoading />;
  }

  if (siteLoadingError) {
    return <ErrorPage error={siteLoadingError.message} />;
  }

  return (
    <div className="root-container">
      <Router>
        <LightGalleryProvider>
          <div className="page-container">
            <Routes>
              {site.pages.map((page) => (
                <Route
                  caseSensitive={false}
                  path={`${site.language.implicit ? '' : `/${site.language.code}`}/${page.slug === 'index' ? '' : page.slug}`}
                  key={page.id}
                  element={<ContentPage page={page} />}
                />
              ))}
              <Route
                path="/editor"
                element={<EditorPage />}
              />
              <Route
                path="*"
                element={<NotFoundPage />}
              />
            </Routes>
          </div>
          {isDeveloperMode && keyDates?.length && (
            <>
              <Dialog
                open={isSettingsModalOpened}
                onClose={() => setIsSettingsModalOpened(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xs"
                fullWidth
              >
                <DialogTitle id="alert-dialog-title">
                  Preview settings
                </DialogTitle>
                <DialogContent sx={{ pt: 2 }}>
                  <Box sx={{ pt: 2, display: 'flex' }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">Date</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={currentKeyDate}
                        label="Date"
                        onChange={(e) => setCurrentKeyDate(e.target.value)}
                      >
                        {keyDates.map((date) => (
                          <MenuItem
                            key={date.toISOString()}
                            value={date.toISOString()}
                            sx={{ ...(now.toISOString() === date.toISOString()) && { color: 'red' } }}
                          >
                            {date.toLocaleDateString(navigator.language, { year: 'numeric', month: '2-digit', day: '2-digit' })}
                            {' '}
                            {date.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' })}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Button onClick={() => setCurrentKeyDate(now.toISOString())}>
                      NOW
                    </Button>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <Button
                    disabled={!currentKeyDate}
                    onClick={() => { setIsSettingsModalOpened(false); }}
                    sx={{ color: 'black' }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!currentKeyDate}
                    onClick={() => {
                      const searchParams = new URLSearchParams(window.location.search);

                      if (currentKeyDate === now.toISOString()) {
                        searchParams.delete('date', currentKeyDate);
                      } else {
                        searchParams.set('date', currentKeyDate);
                      }

                      window.location.search = searchParams.toString();
                    }}
                    sx={{ color: 'black' }}
                  >
                    Apply
                  </Button>
                </DialogActions>
              </Dialog>

              <Fab
                color="primary"
                aria-label="add"
                onClick={() => setIsSettingsModalOpened(true)}
                sx={{
                  position: 'fixed',
                  bottom: '30px',
                  right: '30px',
                }}
              >
                <VisibilityIcon />
              </Fab>
            </>
          )}
        </LightGalleryProvider>
      </Router>
    </div>

  );
}

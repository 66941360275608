import React, {
  createContext, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import ReactDOM from 'react-dom';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-video.css';
import '@vimeo/player';
import {
  Box, Dialog, DialogContent, Divider, Drawer, IconButton, List, ListItem, ListItemText, Typography,
} from '@mui/material';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import LightGallery from 'lightgallery/react';
import MenuIcon from '@mui/icons-material/Menu';
import { Close } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import lgVideo from '../vendor/lightgallery/lgVideo';

export const LightGalleryContext = createContext({});

const customElemId = 'lightbox-menu-btn';
const customCloseId = 'lightbox-close-btn';

function removeElement(id) {
  const elem = document.getElementById(id);
  if (!elem) {
    return;
  }

  return elem.parentNode.removeChild(elem);
}

export function LightGalleryProvider({ children }) {
  const [groups, setGroups] = useState([]);
  const [isHighlightsMenuOpened, setIsHighlightsMenuOpened] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const lightGallery = useRef(null);
  const [navigationOpened, setNavigationOpened] = useState(false);
  const location = useLocation();

  const selectGroup = (group) => {
    setSelectedGroup(group);

    if (!group) {
      return;
    }

    setTimeout(() => {
      lightGallery.current.refresh();
      lightGallery.current.goToNextSlide();
    }, 0);

    setNavigationOpened(false);
  };

  const renderCustomElements = () => {
    if (!lightGallery.current) {
      return;
    }

    removeElement(customElemId);
    removeElement(customCloseId);
    removeElement('lg-download-1');
    removeElement('lg-close-1');
    removeElement('lg-counter-current-1');
    removeElement('lg-counter-all-1');

    const counterElem = lightGallery.current.$toolbar.selector.querySelector('.lg-counter');
    counterElem.innerHTML = '';

    const menuButton = document.createElement('div');
    menuButton.id = customElemId;
    counterElem.prepend(menuButton);

    const closeButton = document.createElement('div');
    closeButton.id = customCloseId;
    counterElem.append(closeButton);

    const element = (
      <>
        <Box sx={{ display: { xs: 'flex', sm: 'none' }, alignItems: 'center' }}>
          <IconButton
            onClick={() => {
              setNavigationOpened(true);
            }}
          >
            <MenuIcon htmlColor="rgb(153, 153, 153)" sx={{ '&:hover': { color: 'white' } }} />
          </IconButton>

          <Box sx={{ mr: 1, color: 'white', fontWeight: 'bold', lineHeight: 1.1, mx: 1 }}>
            {selectedGroup && selectedGroup.name}
            <Box component="span" sx={{ ml: 1, opacity: 0.5 }}>
              [
              <Box component="span" id="lg-counter-current-1" />
              /
              <Box component="span" id="lg-counter-all-1" />
              ]
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: { xs: 'none', sm: 'flex' }, alignItems: 'center', flexWrap: 'wrap' }}>
          {groups.map((group) => (
            <Box
              component="div"
              sx={{
                mr: 1,
                userSelect: 'none',
                color: selectedGroup.id === group.id ? '#f13c3c' : 'white',
                textShadow: '0px 0px 8px #ffffff15',
                fontWeight: 'bold',
                lineHeight: 1.1,
                mx: 1,
                cursor: 'pointer',
                opacity: selectedGroup.id === group.id ? 1 : 1,
              }}
              key={group.id}
              onClick={() => {
                selectGroup(group);
              }}
            >
              {group.name}
            </Box>
          ))}
        </Box>
      </>
    );

    const closeElement = (
      <IconButton
        onClick={() => {
          lightGallery.current.closeGallery();
        }}
      >
        <Close
          htmlColor="rgb(153, 153, 153)"
          sx={{ '&:hover': { color: 'white' } }}
        />
      </IconButton>
    );

    ReactDOM.render((element), document.getElementById(customElemId));
    ReactDOM.render((
      closeElement), document.getElementById(customCloseId));
  };

  const onInit = useCallback((detail) => {
    if (!detail) {
      return;
    }

    lightGallery.current = detail.instance;
    renderCustomElements();
  }, []);

  const registerGroup = useCallback((id, name, order, slides) => {
    console.log('registerGroup', {
      id, name, order, slides,
    });

    setGroups((prev) => {
      if (prev.find((x) => x.id === id)) {
        return prev;
      }

      return [...prev, { id, name, order, items: slides }];
    });
  }, []);

  console.log({ groups });

  const openGallery = useCallback((grouId, id) => {
    const group = groups.find((x) => x.id === grouId);
    setSelectedGroup(group);

    if (!group) {
      return;
    }

    setTimeout(() => {
      lightGallery.current.refresh();
      const idx = group.items.findIndex((x) => x.id === id);

      lightGallery.current.openGallery(idx === -1 ? 0 : idx);
    }, 0);
  }, [groups]);

  useEffect(() => {
    if (!selectedGroup) {
      return;
    }

    renderCustomElements();
  }, [selectedGroup]);

  useEffect(() => {
    if (location.hash !== '#highlights') {
      return;
    }

    setIsHighlightsMenuOpened(true);

    location.hash = '';
    window.history.replaceState('', '', location.pathname);
  }, [location]);

  const data = useMemo(() => ({
    registerGroup,
    openGallery,
  }), [openGallery, registerGroup]);

  return (
    <LightGalleryContext.Provider value={data}>
      {children}
      <LightGallery
        speed={500}
        plugins={[lgVideo, lgThumbnail]}
        selector=".lightbox-image"
        exThumbImage="data-thumb"
        vimeoPlayerParams={{ muted: 0 }}
        onInit={onInit}
      >
        {selectedGroup && selectedGroup.items.map((item) => (
          <Box
            key={item.id}
            className="lightbox-image"
            data-src={item.src}
            data-thumb={item.thumb}
            {...(item.slideTitle || item.slideText) && {
              'data-sub-html': `${item.slideTitle ? `<h4>${item.slideTitle}</h4>` : ''}${item.slideText ? `<p>${item.slideText}</p>` : ''}`,
            }}
          />
        ))}

      </LightGallery>
      <Drawer
        anchor="left"
        open={navigationOpened}
        onClose={() => {
          setNavigationOpened(false);
        }}
        PaperProps={{
          sx: {
            width: 250,
            backgroundColor: '#0d0a0a',
            color: 'white',
          },
        }}
      >
        <List
          component="nav"
          sx={{
            flex: '1 1 0px',
            overflowY: 'auto',
            pt: 1,
          }}

        >
          {groups.sort((a, b) => a.order - b.order).map((group, groupIdx) => (
            <ListItem
              key={group.id}
              button
              onClick={() => {
                selectGroup(group);
              }}
            >
              <ListItemText
                disableTypography
                primary={(
                  <Typography
                    sx={{ lineHeight: 1.1, fontWeight: (selectedGroup || {}).id === group.id ? 'bold' : 'regular', opacity: (selectedGroup || {}).id === group.id ? 1 : 1, color: (selectedGroup || {}).id === group.id ? '#f13c3c' : 'white' }}

                  >
                    {group.name}
                  </Typography>
                )}
              />
            </ListItem>
          ))}
        </List>
        <Divider />
      </Drawer>
      <Dialog
        sx={{ '& .MuiDialog-paper': { maxHeight: 700 } }}
        maxWidth="xs"
        open={isHighlightsMenuOpened}
        onClose={() => {
          setIsHighlightsMenuOpened(false);
        }}
        disableScrollLock
      >
        <DialogContent sx={{
          padding: '0px 10px',
        }}
        >
          <List
            component="nav"
            sx={{
              flex: '1 1 0px',
              overflowY: 'auto',
              pt: 1,
            }}

          >
            {groups.sort((a, b) => a.order - b.order).map((group, groupIdx) => (
              <ListItem
                key={groupIdx}
                button
                onClick={() => {
                  lightGallery.current.closeGallery();
                  setNavigationOpened(false);

                  setTimeout(() => {
                    const newGroup = groups.find((x) => x.id === group.id);
                    openGallery(newGroup.id);
                  }, 500);
                  setIsHighlightsMenuOpened(false);
                }}
              >
                <ListItemText
                  disableTypography
                  primary={(
                    <Typography>
                      {group.name}
                    </Typography>
                  )}
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>

      </Dialog>
    </LightGalleryContext.Provider>
  );
}

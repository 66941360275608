import { Box, Button } from '@mui/material';
import React from 'react';
import InnerHTML from 'dangerously-set-html-content';
import MobileDetect from 'mobile-detect';
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import { useCurrentBreakpoint } from '../../hooks';

const AnchorButton = ({ color, label, ...props }) => {
  const currentTheme = useTheme();

  const newTheme = createTheme({
    ...currentTheme,
    ...color && { palette: {
      ...currentTheme.palette,
      primary: {
        main: color,
      },
    } },
  });

  return (
    <ThemeProvider theme={newTheme}>
      <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', mt: 2 }}>
        <Button {...props} color="primary" type="submit">
          {label}
        </Button>
      </Box>
    </ThemeProvider>
  );
};

const md = new MobileDetect(window.navigator.userAgent);
const isIphone = md.is('iPhone');

export const FullScreenImage = ({
  image, localizedImage, title, textColor = 'white', text, buttonLabel, buttonAnchor, buttonColor, parallax,
  height, parallaxOffsetX = 0, parallaxOffsetY = 0, visibility,
}) => {
  const breakpoint = useCurrentBreakpoint();

  if (visibility && visibility.length && !visibility.includes(breakpoint)) {
    return null;
  }

  return (
    <Box sx={{
      width: '100vw',
      height: `${height || 100}vh`,
      backgroundPosition: 'center',
      backgroundImage: `url(${localizedImage ?? image})`,
      backgroundSize: 'cover',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      ...parallax && {
        backgroundAttachment: { xs: isIphone ? 'scroll' : 'fixed', md: 'fixed' },
        backgroundRepeat: 'no-repeat',
        backgroundPosition: `calc(50% + ${window.innerWidth * (parallaxOffsetX || 0) / 100}px) calc(50% + ${window.innerHeight * (parallaxOffsetY || 0) / 100}px)`,
      },
    }}
    >
      <Box>
        <Box
          component="h1"
          sx={{
            fontSize: { xs: '40px', sm: '60px', md: '80px' },
            color: textColor,
            textAlign: 'center',
            whiteSpace: 'pre-line',
            my: 0,
          }}
        >
          {title}
        </Box>
        {text && (
          <Box
            sx={{
              color: textColor,
              textAlign: 'center',
              my: 0,
            }}
            component="div"
          >
            <InnerHTML html={text} />
          </Box>
        )}
        {buttonLabel && buttonAnchor && (
          <AnchorButton
            label={buttonLabel}
            color={buttonColor}
            variant="contained"
            href={buttonAnchor}
          />
        )}
      </Box>
    </Box>
  );
};
